import React, {useEffect, useContext} from 'react'

import Main from './Pages/Main/Components/Main/Main'
import { Route, Routes } from 'react-router';
import Courses from './Pages/Courses/Courses';
// import PDFViewer from './CommonComponents/PDFViewer/PDFViewer';
import { observer } from 'mobx-react';
import Articles from './Pages/Articles/Articles';
import Tests from './Pages/Tests/Tests';
import LoginForm from './Pages/Login/LoginForm';
import AuthPage from './Pages/Auth/AuthPage';
import RegistrationForm from './Pages/Auth/Components/RegistrationPage/RegistrationForm';
import TestPage from './Pages/Tests/TestPage/TestPage';
import CoursePage from './Pages/Courses/CoursePage/CoursePage'
import Cabinet from './Pages/Cabinet/Cabinet';
import AboutPage from './Pages/About/AboutPage';
import { authContext, balanceContext } from '.';
import PDFPage from './Pages/CreatePDF/PDFPage';
import ArticleOne from './Pages/Articles/Pages/ArticleOne';
import ArticleTwo from './Pages/Articles/Pages/ArticleTwo';
import ArticleThree from './Pages/Articles/Pages/ArticleThree';
import Sertificates from './Pages/Sertificates/Sertificates';
import Programs from './Pages/Programs/Programs';


function App() {


  const {authStore} = useContext(authContext)
  const {balanceStore} = useContext(balanceContext)



  useEffect(() => {
    if (localStorage.getItem('token')) {
      const id = localStorage.getItem('user')
      balanceStore.getBalance(id)
      authStore.checkAuth()
      authStore.setLoading(false)
        
  }
    else {
      authStore.setLoading(false)
    }
  }, [authStore.isAuth])

  // useEffect(() => {
  //   if (authStore.isAuth) {
  //   const id = localStorage.getItem('user')
  //   balanceStore.getBalance(id)
  //   }
  //   return
  // },[])

  return (
    
    <div className="page">
      {authStore.isLoading ? <div className='loading'>загрузка...</div> : 
    <Routes>
    <Route exact path = '/' element ={<Main/>}/>
    <Route  path = 'courses' element = {<Courses/>}/>
    <Route path ='articles' element = {<Articles/>}>
    </Route>
    <Route path = '/articles/article-toxics' element = {<ArticleThree/>}/>
    <Route path = '/articles/article-basics' element = {<ArticleOne/>}/>
    <Route path = '/articles/article-advanced' element = {<ArticleTwo/>}/>
    <Route path = 'about' element = {<AboutPage/>}/>
    <Route path = 'cabinet' element = {<Cabinet/>}/>
    <Route path = 'programs' element = {<Programs/>}/>
    {/* <Route path="/pdf/:filename" element={<PDFViewer />} /> */}
    <Route path = 'sertificates' element = {<Sertificates></Sertificates>}></Route>
    <Route path ='tests' element = {<Tests/>}/>
    <Route path = 'createPdf' element = {<PDFPage/>}></Route>
    <Route path = 'auth' element = {<AuthPage/>}>
    <Route path = '/auth/login' element={<LoginForm/>}/>
    <Route path = '/auth/registration' element = {<RegistrationForm/>}/>
    </Route>
    <Route path = '/test/:id' element ={<TestPage/>}></Route>
    <Route path = '/course/:id' element = {<CoursePage></CoursePage>}></Route>
  </Routes>}
    </div>
  );
}

export default observer(App);
